import React, { Fragment } from 'react'

// Gatsby
import { graphql } from 'gatsby'

// Layouts
import MainLayout from '@layouts/MainLayout'

// Components
import PostList from '@components/PostList'
import SEO from '@components/SEO'

const Posts = ({ data }) => {
  const {
    allMdx: { edges }
  } = data

  return (
    <Fragment>
      <SEO
        title='Yazılar'
        description='Frontend development ile ilgili güncel ve faydalı yazılar'
        keywords='Frontend, Development, Yazılım, Faydalı Bilgiler, Blog, Yazılar'
      />
      <MainLayout>
        <div className='main__content'>
          <PostList edges={edges} title='Yazılar' />
        </div>
      </MainLayout>
    </Fragment>
  )
}

export default Posts

export const postsQuery = graphql`
  query postsQuery {
    allMdx(
      sort: { order: DESC, fields: [frontmatter___createdAt] }
      filter: { frontmatter: { articleType: { eq: "post" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
            readingTime {
              minutes
            }
          }
          frontmatter {
            createdAt(formatString: "DD MMMM YYYY", locale: "tr")
            title
            tags
          }
        }
      }
    }
  }
`
